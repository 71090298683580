import React from 'react'
import Ink from 'react-ink'
import { parseQuery } from 'lib/string'
import Footer from 'app/footer/Footer'
import { get } from 'lib/api'
import { backoffice, api, homebase } from '@chilipiper/service/lib/endpoint'
import './internal.scss'

class Internal extends React.Component {
  state = {
    showAlert: false,
    hasError: parseQuery().error !== undefined,
    error: parseQuery().error,
    step: parseQuery().step,
    displayName: decodeURIComponent(parseQuery().displayName),
    picture: decodeURIComponent(parseQuery().picture),
    loading: true,
  }

  componentDidMount() {
    get(
      backoffice('/internal/session'),
      session => {
        this.setState({ session, loading: false })
      },
      () => {
        this.setState({ loading: false })
      }
    )
  }

  logIn = () => {
    window.location = api('/oauth2/google?flow=internal')
  }

  renderLoginButton() {
    return (
      <div className='login-button step-setup-button' onClick={this.logIn}>
        <Ink />
        <button style={{ width: '150px' }}>{'Login'}</button>
      </div>
    )
  }

  logOut = () => {
    window.location = api('/api/v1/internal/logout')
  }

  renderLogoutButton() {
    return (
      <div className='login-button step-setup-button' onClick={this.logOut}>
        <Ink />
        <button style={{ width: '150px' }}>{'Logout'}</button>
      </div>
    )
  }

  renderLogin() {
    return (
      <div className='login-window'>
        <div className='chilipiper-info'>
          <div className='microsoft-title' />
          <div className='microsoft-certified' />
          <div className='chilipiper-internal' />
        </div>

        <div className='step-box2' style={{ width: '150px' }}>
          {this.renderLoginButton()}
        </div>
      </div>
    )
  }

  renderError() {
    const url = `url(${this.state.picture})`
    return (
      <div className='login-window'>
        <div className='chilipiper-info'>
          <div className='microsoft-title' />
          <div className='microsoft-certified'>
            {this.state.displayName} you should use Chili Piper email address if you want to login
          </div>
          <div className='chilipiper-big-picture' style={{ backgroundImage: url }} />
        </div>

        <div className='step-box2' style={{ width: '150px' }}>
          {this.renderLoginButton()}
        </div>
      </div>
    )
  }

  renderSuccess() {
    const url = `url(${this.state.session.picture})`
    return (
      <div className='login-window'>
        <div className='chilipiper-success'>
          <div className='chilipiper-user-info'>
            <div className='chilipiper-small-picture' style={{ backgroundImage: url }} />
            <div>{this.state.session.displayName}</div>
          </div>
          <div>You have access to:</div>
          <div>
            - <a href={homebase('/accounts')}>Accounts management</a>
          </div>
          <div>- Trials management</div>
          <div>- Impersonation API</div>
        </div>
        <div>
          <br />
          {this.renderLogoutButton()}
        </div>
      </div>
    )
  }

  renderView() {
    if (this.state.loading) {
      return <div className='login-window'>...</div>
    }
    if (this.state.hasError) {
      return this.renderError()
    }
    if (this.state.session) {
      return this.renderSuccess()
    }
    return this.renderLogin()
  }

  render() {
    return (
      <div className='login-wrapper microsoft-wrapper'>
        <div className='login'>
          <div className='actionbar'>
            <div className='site-logo' />
          </div>

          {this.renderView()}
          <Footer />
        </div>
      </div>
    )
  }
}

export default Internal
