import React from 'react'
import Datagrid from 'app/components/datagrid/Datagrid'
import Column from 'app/components/datagrid/Column'
import { get } from 'lib/api'
import * as endpoint from '@chilipiper/service/lib/endpoint'
import _ from 'lodash'

import {
  ActiveUsersRenderer,
  EnabledUsersRenderer,
  MeetingsBookedRenderer,
  SalesforceLinkRenderer,
  InboundRouterRenderer,
  CallInitiatedRenderer,
  CallConnectedRenderer,
  adminRenderer,
  dateRenderer,
  scoreRenderer,
  licensesRenderer,
  createBooleanRenderer,
  nameRenderer,
} from './AccountsRenderers'

class AccountsOld extends React.Component {
  state = { accounts: [] }

  componentDidMount() {
    get(endpoint.backoffice('/admin/list-accounts'), result => {
      result.forEach((account, index) => {
        account.index = index
      })
      this.setState({ accounts: result })
    })
  }

  onOrder = order => {
    let direction = order.asc ? 'asc' : 'desc'
    let sort = _.orderBy(
      this.state.accounts,
      [order.field, 'installed', 'name'],
      [direction, 'desc', 'asc']
    )
    this.setState({ accounts: sort })
  }

  activeUsersRenderer = item => <ActiveUsersRenderer item={item} />

  meetingsBookedRenderer = item => <MeetingsBookedRenderer item={item} />

  inboundRouterRenderer = item => <InboundRouterRenderer item={item} />

  salesforceLinkRenderer = item => <SalesforceLinkRenderer item={item} />

  callInitiatedRenderer = item => <CallInitiatedRenderer item={item} />

  callConnectedRenderer = item => <CallConnectedRenderer item={item} />

  indexRenderer = (item, index) => (
    <div className='fabe-index-renderer'>
      <span>{index}.</span>
    </div>
  )

  columns = [
    <Column title='index' header='#' renderer={this.indexRenderer} />,
    <Column title='name' header='Company' renderer={nameRenderer} field='name' />,

    <Column title='hot' header='Hot' getField={item => item.licenses.meetingsHot} total={true} />,
    <Column
      title='spicy'
      header='Spicy'
      getField={item => item.licenses.meetingsSpicy}
      total={true}
    />,
    <Column title='free' header='Free' getField={item => item.licenses.free} total={true} />,
    <Column
      title='concierge'
      header='Concierge'
      renderer={createBooleanRenderer(item => item.licenses.concierge)}
    />,
    <Column
      title='live'
      header='Live'
      renderer={createBooleanRenderer(item => item.licenses.conciergeLiveCalls)}
    />,
    <Column
      title='stripe'
      header='Stripe Org'
      renderer={createBooleanRenderer(item => item.stripe.info)}
    />,

    <Column title='sf' header='Salesforce Account' renderer={this.salesforceLinkRenderer} />,
    <Column title='admin' header='Admin' renderer={adminRenderer} />,
    <Column title='trial' header='Expiration Date' renderer={dateRenderer} field='trialExpires' />,
  ]

  render() {
    if (this.state.accounts.length <= 0) {
      return <div>...</div>
    }
    return (
      <div className='fabe-accounts-list'>
        <Datagrid
          data={this.state.accounts}
          columns={this.columns}
          onOrder={this.onOrder}
          totalPlacement='bottom'
        />
      </div>
    )
  }
}

export default AccountsOld
