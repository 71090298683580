import React from 'react'
import ReactJson from 'react-json-view'

export default class RawSfQueryResult extends React.PureComponent {
  render() {
    const { result } = this.props

    return <ReactJson src={result} />
  }
}
