import React from 'react'
import PropTypes from 'prop-types'
import Pager from './Pager'
import './datagrid.scss'

class Datagrid extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    search: PropTypes.object,
    pager: PropTypes.object,
    emptyRenderer: PropTypes.object,
    onSearch: PropTypes.func,
    onPageChange: PropTypes.func,
  }

  state = {
    data: this.props.data || [],
    page: 1,
    filter: '',
  }

  componentDidMount() {
    document.addEventListener('order', this.orderHandler, false)
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data || [] })
  }

  orderHandler = event => {
    if (this.props.onOrder) {
      this.props.onOrder(event.detail)
    }
  }

  onSearch = event => {
    this.setState({ filter: event.target.value, page: 1 }, () => {
      this.props.onSearch(this.state.filter)
    })
  }

  onPageChange = page => {
    this.setState({ page: page }, () => {
      this.props.onPageChange(page)
    })
  }

  renderSearch() {
    if (this.props.search || this.props.onSearch) {
      return (
        <div className='fabe-datagrid-search'>
          <input type='text' placeholder='Search user by name' onChange={this.onSearch} />
        </div>
      )
    }
    return null
  }

  renderNoElement() {
    if (this.state.data.length === 0) {
      if (this.props.emptyRenderer) {
        return this.props.emptyRenderer
      }
      return (
        <div className='fabe-no-elements'>
          <span>Empty list</span>
        </div>
      )
    }
    return null
  }

  renderPaging() {
    if (this.props.pager) {
      return (
        <Pager page={this.state.page} pager={this.props.pager} onPageChange={this.onPageChange} />
      )
    }
    return null
  }

  render() {
    let columns = (this.props.columns || []).map((column, index) => {
      return React.cloneElement(column, {
        key: `fabe-column-index${index}`,
        index: index,
        data: this.state.data,
        grid: this.props,
      })
    })
    return (
      <div className='fabe-datagrid'>
        {this.renderSearch()}
        <div className='fabe-datagrid-columns'>{columns}</div>
        {this.renderNoElement()}
        {this.renderPaging()}
      </div>
    )
  }
}

export default Datagrid
