import React from 'react'

const Footer = () => (
  <footer className='site-footer'>
    <nav className='footer-nav' />
    <aside className='credits'>
      <span>© 2018 Chili Piper</span>
      <span>All Rights Reserved</span>
    </aside>
  </footer>
)

export default Footer
