import React from 'react'
import { render } from 'react-dom'
import { Router, Route, IndexRedirect, browserHistory } from 'react-router'
import App from './App'
import NotFound from './navigation/NotFound'
import AccountsOld from './accounts/AccountsOld'
import { Accounts } from './accounts/Accounts'
import Account from './accounts/Account'
import Query from './query/Query'
import RawSfQuery from './raw-sf-query/RawSfQuery'
import { ZoomUsers } from './accounts/ZoomUsers'
import { EnableProducts } from './accounts/EnableProducts'
import { CrmUsageQuery } from './crm-usage-query/CrmUsageQuery'

const adminRouter = () => (
  <Router history={browserHistory}>
    <Route path='/' component={App}>
      <IndexRedirect to='/accounts' />
      <Route path='accounts/:id' component={Account} />
      <Route path='accounts/:id/zoom' component={ZoomUsers} />
      <Route path='accounts/:id/products' component={EnableProducts} />
      <Route path='accounts' component={Accounts} />
      <Route path='accounts-old' component={AccountsOld} />
      <Route path='query' component={Query} />
      <Route path='crm-usage-query' component={CrmUsageQuery} />
      <Route path='raw-sf-query' component={RawSfQuery} />
      <Route path='*' component={NotFound} />
    </Route>
  </Router>
)

export default {
  mount: () => {
    let mount = document.getElementById('root')
    let router = adminRouter()
    render(router, mount)
  },
}
