import React from 'react'
import { get } from 'lib/api'
import * as endpoint from '@chilipiper/service/lib/endpoint'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

export class ZoomUsers extends React.Component {
  state = {
    loading: true,
    users: [],
    searchValue: '',
    account: null,
  }

  componentDidMount() {
    get(endpoint.backoffice(`/admin/accounts/${this.props.params.id}`), account => {
      this.setState({ account })
      get(`/business/user/connections`, data => {
        const connection = data.find(
          connection => connection.system === 'zoom' && connection.connected
        )
        if (connection) {
          get(`/back-office/zoom-users/${account.account.id}`, data =>
            this.setState({ users: data, loading: false })
          )
        } else {
          this.setState({ loading: false })
        }
      })
    })
  }

  getFilteredUsers = () => {
    const { users, searchValue } = this.state
    return users.filter(user => {
      return [user.email, user.first_name, user.last_name].join().includes(searchValue)
    })
  }

  render() {
    const { account, loading, users } = this.state
    if (!loading && !users.length) {
      return (
        <Typography variant='h5'>
          No zoom users found {account && `for ${account.account.name}`}
        </Typography>
      )
    }
    return (
      <div>
        <Typography variant='h4'>
          Search zoom users {account && `in ${account.account.name}`}
        </Typography>
        <div className='table-container'>
          <form onSubmit={this.search} className='search-form'>
            <TextField
              label='Search user'
              onChange={e => this.setState({ searchValue: e.target.value })}
            />
          </form>
          <TableContainer component={Paper} className='table'>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Fist Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.getFilteredUsers().map(row => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell component='th' scope='row'>
                      {row.first_name}
                    </TableCell>
                    <TableCell>{row.last_name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    )
  }
}
