export const sortingOptions = [
  { value: 'id', label: 'ID' },
  { value: 'tenantId', label: 'Tenant ID' },
  { value: 'routeId', label: 'Route ID' },
  { value: 'routerId', label: 'Router ID' },
  { value: 'reportId', label: 'Report ID' },
  { value: 'workspaceId', label: 'Workspace ID' },
  { value: 'requestId', label: 'Request ID' },
  { value: 'userId', label: 'User ID' },
  { value: 'created', label: 'Created' },
  { value: 'durationMilli', label: 'Duration in milliseconds' },
  { value: 'operationInput', label: 'Operation Input' },
  { value: 'crmOperationType', label: 'Crm Operation Type' },
  { value: 'crmType', label: 'Crm Type' },
  { value: 'client', label: 'Client' },
  { value: 'service', label: 'Service' },
]

export const existsOptions = [
  { value: 'routeId', label: 'Route ID' },
  { value: 'routerId', label: 'Router ID' },
  { value: 'reportId', label: 'Report ID' },
  { value: 'workspaceId', label: 'Workspace ID' },
  { value: 'requestId', label: 'Request ID' },
  { value: 'userId', label: 'User ID' },
]

export const parseExistsFilterToQuery = (filterObjectsArray, value) => {
  return filterObjectsArray.reduce((agg, param, index) => {
    return index !== 0 ? `${agg}&${param.value}Exists=${value}` : `${param.value}Exists=${value}`
  }, '')
}
