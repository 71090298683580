import React from 'react'
import ReactDOM from 'react-dom'
import { x, backoffice } from '@chilipiper/service/lib/endpoint'

import Routing from 'app/Routing'
import Internal from 'app/Internal'
import session from 'lib/session'
import { get, session as apiSession } from 'lib/api'

const href = window.location.href
const isGuest = href.indexOf('/book/') !== -1 || href.indexOf('/router') !== -1
const isLogin = href.indexOf('/login') !== -1
const isHomebase = href.indexOf('index.html') !== -1 || href.indexOf('.html') === -1

function mountRouting(admin) {
  if (document.getElementById('root')) {
    Routing.mount(admin)
  }
}

function mount(what, when) {
  if (when) {
    const success = result => {
      session.create(result)
      mountRouting(result.admin, isGuest)
    }
    const error = () => {
      if (!isLogin && !isGuest) {
        window.location.href = x('/login.html')
      } else {
        mountRouting(false, isGuest)
      }
    }
    get(what, success, error)
  }
}

function boot(app, mountTo) {
  if (document.getElementById(mountTo)) {
    ReactDOM.render(React.createElement(app), document.getElementById(mountTo))
  }
}

function mountViews() {
  apiSession(() => {
    boot(Internal, 'login')
  })
}

mount(backoffice('/internal/session'), isHomebase && !isGuest)

mountViews()
