import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import * as endpoint from '@chilipiper/service/lib/endpoint'
import { hashCode } from 'lib/string'
import { get } from 'lib/api'
import session from '../session'
import './profile.css'

class Profile extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    onMouseOver: PropTypes.func,
    identifier: PropTypes.string,
    size: PropTypes.string,
    name: PropTypes.string,
  }

  state = {
    profile: {},
  }

  componentDidMount() {
    this.componentWillReceiveProps(this.props)
  }

  componentWillReceiveProps(props) {
    if (this.state.identifier !== props.identifier) {
      this.setState({ identifier: props.identifier, profile: {} }, () => {
        let link = endpoint.profiles(`/profile/${this.props.identifier}`)
        get(link, profile => {
          this.setState({ profile: profile || {} })
        })
      })
    }
  }

  render() {
    let tooltip = this.props.tooltip
    let identifier = this.props.identifier
    let profile = this.state.profile
    let name =
      this.props.name || (session.getUser() && session.getUser().firstName) || this.props.identifier
    let letters = name ? name.charAt(0).toUpperCase() : '?'
    let size = this.props.size || '24px'
    let color = ['#C767A7', '#749EDA', '#9768AC', '#E3AC2D', '#9AC562', '#749EDA'][
      hashCode(identifier) % 5
    ]
    let style = {
      width: size,
      height: size,
      backgroundColor: color,
      backgroundSize: 'cover',
    }
    if (profile.image) {
      style.backgroundImage = `url(${profile.image})`
    }

    return (
      <div className='fabe-profile-holder'>
        {tooltip && <ReactTooltip id='name-tooltip' place='bottom' type='dark' />}
        <div
          data-tip={tooltip}
          data-for='name-tooltip'
          className='fabe-profile'
          style={style}
          onMouseOver={this.props.onMouseOver}
          onFocus={() => {}}
        >
          <div className='fabe-profile-letters'>{profile.image ? '' : letters}</div>
        </div>
      </div>
    )
  }
}

Profile.defaultProps = {
  active: false,
}

export default Profile
