import React from 'react'
import { get, post, put } from 'lib/api'
import PropTypes from 'prop-types'
import Datagrid from 'app/components/datagrid/Datagrid'
import Column from 'app/components/datagrid/Column'
import FlatButton from 'material-ui/FlatButton'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button/Button'
import Snackbar from 'app/components/Snackbar'
import Action from 'app/query/Action'
import { Link } from 'react-router'
import { backoffice, homebase } from '@chilipiper/service/lib/endpoint'

class Account extends React.Component {
  state = {
    displayZoomLink: false,
    targetTenantId: '',
  }

  static propTypes = {}

  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  urlParams = {}

  componentDidMount() {
    this.bootstrap()
    this.loadCrmStatus()
    this.urlParams = new URLSearchParams(window.location.search)
  }

  bootstrap = () => {
    get(backoffice(`/admin/accounts/${this.props.params.id}`), data => {
      this.setState(data)
      get(backoffice(`/back-office/zoom-users/${data.account.id}`), data => {
        if (data.length) {
          this.setState({ displayZoomLink: true })
        }
      })
    })
  }

  loadCrmStatus = () => {
    get(
      backoffice(`/admin/crm-status/${this.props.params.id}`),
      ({ sandbox }) => this.setState({ sandbox }),
      () => this.setState({ sandbox: null })
    )
  }

  setSandbox = sandbox => {
    this.setState({ sandbox })
    post(backoffice(`/admin/crm-sandbox/${this.props.params.id}`), {
      data: { sandbox },
      callback: this.loadCrmStatus,
      error: this.loadCrmStatus,
    })
  }

  syncLicenses = () => {
    post(backoffice(`/admin/accounts/${this.props.params.id}/sync-licences`), {
      callback: this.bootstrap,
      error: async response => {
        const error = await response.json()
        return Snackbar.show({ text: error.message })
      },
    })
  }

  renderLicensesSyncButton = label => {
    return (
      <div>
        {label}
        <FlatButton label='Sync now' onClick={this.syncLicenses} />
      </div>
    )
  }

  renderLicensesStats = productLicences => {
    if (!productLicences) {
      return null
    }
    const total =
      ((productLicences.meetings || {}).nrOfLicences || 0) +
      ((productLicences.concierge || {}).nrOfLicences || 0) +
      ((productLicences.mildMeetings || {}).nrOfLicences || 0) +
      ((productLicences.events || {}).nrOfLicences || 0)
    return (
      <div>
        <span>Total: {total}</span> <span>Meetings: {productLicences.meetings.nrOfLicences}</span>{' '}
        <span>Events: {productLicences.events.nrOfLicences}</span>
      </div>
    )
  }

  rendereFor(field) {
    return item => {
      if (field === 'name' && item.type === 'licenses') {
        return this.renderLicensesSyncButton(item.name)
      }
      if (field === 'value' && item.type === 'licenses') {
        return this.renderLicensesStats(item[field])
      }

      return item[field]
    }
  }

  data() {
    const s = this.state
    const account = s.account || {}
    const success = s.success || {}
    return [
      { name: 'id', value: account.id },
      { name: 'subDomain', value: account.subDomain },
      { name: 'domains', value: (s.domains || []).join(', ') },
      { name: 'salesforceId', value: success.salesforceId },
      { name: 'licenses', value: success.productLicences, type: 'licenses' },
    ]
  }

  columns = [
    <Column title='name' header='Name' renderer={this.rendereFor('name')} field='name' />,
    <Column title='value' header='Value' renderer={this.rendereFor('value')} field='value' />,
  ]

  saveSalesforceId() {
    const s = this.state
    const success = s.success || {}
    put(
      backoffice(
        `/admin/accounts/${this.props.params.id}/change-sf-id?salesforceId=${success.salesforceId}`
      ),
      {
        callback: () => {
          location.reload()
        },
      }
    )
  }

  onDelete = () => {
    if (window.confirm('Are you totally sure you want to delete this account?')) {
      get(backoffice(`/admin/uninstall?account=${this.state.account.id}`), () => {
        window.location.href = homebase('/accounts')
      })
    }
  }

  onUpdateAccountSubdomain = value => {
    put(backoffice(`/admin/change-subdomain?tenantId=${this.state.account.id}&subDomain=${value}`), {
      callback: () => {
        location.reload()
      },
      error: async response => {
        const text = await response.text()
        console.log(text)
        Snackbar.show({ text })
      },
    })
  }

  onAddAccountDomain = value => {
    put(backoffice(`/admin/add-domain?tenantId=${this.state.account.id}&domain=${value}`), {
      callback: () => {
        location.reload()
      },
    })
  }

  onRemoveAccountDomain = value => {
    del(backoffice(`/admin/remove-domain?tenantId=${this.state.account.id}&domain=${value}`), {
      callback: () => {
        location.reload()
      },
    })
    this.setState({ domains: state.domains.filter(domain !== domain) })
  }

  getCalendarType = () => {
    return this.state.account.connections?.google != null ? 'Google' : 'Microsoft'
  }

  onRemoveConnection = () => {
    const type = this.getCalendarType()
    const tenantId = this.state.account.id
    post(backoffice(`/admin/remove-connection?type=${type}&tenantId=${tenantId}`), {
      callback: () => {
        Snackbar.show({ text: 'Connection removed' })
      },
    })
  }

  onMoveConnection = () => {
    const type = this.getCalendarType()
    const from = this.state.account.id
    const to = this.state.targetTenantId
    post(backoffice(`/admin/move-connection?type=${type}&from=${from}&to=${to}`), {
      callback: () => {
        Snackbar.show({ text: 'Connection moved' })
      },
    })
  }

  renderDanger() {
    const domains = this.state.domains ?? []
    const calendarType = this.getCalendarType()

    return (
      <div className='fabe-danger-zone'>
        <span className='fabe-zone-title'>Danger Zone</span>
        <Action
          handler={this.onUpdateAccountSubdomain}
          title='Update Account Subdomain'
          inputType='text'
          subtitle={`What's the new subdomain?`}
        />
        <div className='action-container'>
          <h3>Domains:</h3>
          {domains.map(domain => (
            <button type='button' onClick={() => this.onRemoveAccountDomain(domain)}>
              × {domain}
            </button>
          ))}
        </div>
        <Action
          handler={this.onAddAccountDomain}
          title='Add Account Domain'
          inputType='text'
          subtitle={`What's the account domain?`}
        />
        <div>
          <span>
            Tenant's instance will become unusable and will be scheduled for deletion(deleted in 30
            days)
          </span>
          <button type='button' onClick={this.onDelete}>
            Delete Account
          </button>
        </div>
        <div>
          <Action
            handler={this.onRemoveConnection}
            title={`Remove ${calendarType} calendar connection`}
            toggleable={false}
          />
        </div>
        <div>
          <Action
            handler={this.onMoveConnection}
            title={`Move ${calendarType} calendar connection`}
            inputType='text'
            subtitle={`Target tenant ID`}
          />
        </div>
      </div>
    )
  }

  render() {
    const s = this.state
    const success = s.success || {}
    const isDanger = this.urlParams.get && this.urlParams.get('danger')
    if (!s.account) {
      return null
    }
    return (
      <div className={isDanger ? 'fabe-danger-style' : ''}>
        <div className='fabe-zoom-link'>
          {s.displayZoomLink && (
            <Link to={`/accounts/${s.account.id}/zoom`}>
              <Button>Search zoom users</Button>
            </Link>
          )}
          <Link to={`/accounts/${s.account.id}/products`}>
            <Button>Product activation</Button>
          </Link>
        </div>
        <div className='fabe-accounts-list'>
          <Datagrid data={this.data()} columns={this.columns} onOrder={this.onOrder} />
          <div className='account-actions'>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <TextField
                  label='Salesforce ID'
                  variant='outlined'
                  size='small'
                  value={success.salesforceId}
                  onChange={e =>
                    this.setState({
                      success: { ...success, salesforceId: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <Button variant='outlined' onClick={() => this.saveSalesforceId()}>
                  Save
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={s.sandbox == null}
                      checked={s.sandbox ?? false}
                      onChange={(e, value) => this.setSandbox(value)}
                    />
                  }
                  label='Sandbox'
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {isDanger && this.renderDanger()}
      </div>
    )
  }
}

export default Account
