import React from 'react'
import PropTypes from 'prop-types'

// pager object:
// page: 1,
// total: this.data.length,
// size: 10,
// onPage: this.onPage

class Pager extends React.Component {
  static propTypes = {
    onPageChange: PropTypes.func,
    pager: PropTypes.object,
    page: PropTypes.number,
  }

  onPage = page => {
    this.props.onPageChange(page)
  }

  renderPages() {
    let pager = this.props.pager
    let pages = []

    for (let i = 1; i < Math.ceil(pager.total / pager.size) + 1; i++) {
      pages.push(i)
    }

    return pages.map(page => (
      <a
        key={page}
        className={`number${this.props.page === page ? ' active' : ''}`}
        onClick={() => this.onPage(page)}
      >
        {page}
      </a>
    ))
  }

  render() {
    let pager = this.props.pager
    let lastPage = Math.ceil(pager.total / pager.size)
    let leftHidden = this.props.page === 1 ? 'hidden' : ''
    let rightHidden = this.props.page === lastPage ? 'hidden' : ''

    return (
      <div className={!lastPage || lastPage === 1 ? 'hidden' : 'fabe-datagrid-pager'}>
        <div
          className={`fabe-pager-previous ${leftHidden}`}
          onClick={() => this.onPage(this.props.page - 1)}
        ></div>
        {this.renderPages()}
        <div
          className={`fabe-pager-next ${rightHidden}`}
          onClick={() => this.onPage(this.props.page + 1)}
        ></div>
      </div>
    )
  }
}

export default Pager
