import React from 'react'
import { get, post } from 'lib/api'
import { backoffice } from '@chilipiper/service/lib/endpoint'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import CircularProgress from '@material-ui/core/CircularProgress'

const isMeetingsProduct = p => p.name === 'Meetings' || p.name === 'MildMeetings'

const ProductRow = ({ product, toggle, withPadding }) => {
  return (
    <TableRow key={product.name}>
      <TableCell component='th' scope='row' style={withPadding ? { paddingLeft: '50px' } : {}}>
        {product.displayName || product.name}
      </TableCell>
      <TableCell>
        <Switch onChange={e => toggle(e, product)} checked={product.enabled} />
      </TableCell>
    </TableRow>
  )
}

export class EnableProducts extends React.Component {
  state = {
    products: [],
    account: null,
    loading: true,
  }

  componentDidMount() {
    get(backoffice(`/admin/accounts/${this.props.params.id}`), account => {
      this.setState({ account })
      this.loadProducts()
    })
  }

  loadProducts = () => {
    get(backoffice(`/back-office/products/${this.props.params.id}`), data => {
      this.setState({ products: data, loading: false })
    })
  }

  toggleProduct = (event, product) => {
    const { account, products } = this.state
    post(backoffice(`/back-office/enable-product`), {
      data: {
        accountId: account.account.id,
        product: product.name,
        enable: event.target.checked,
      },
      callback: () => {
        this.setState({
          products: products.map(p => {
            if (p.name === product.name) {
              return {
                ...p,
                enabled: !p.enabled,
              }
            }
            return p
          }),
        })
      },
    })
  }

  render() {
    const { products, account, loading } = this.state
    const productsWithoutMeetings = products.filter(p => !isMeetingsProduct(p))
    const meetingsProducts = products.filter(isMeetingsProduct).map(p => ({
      ...p,
      displayName: p.name === 'Meetings' ? 'Hot workspace' : 'Spicy workspace',
    }))
    if (loading) {
      return <CircularProgress />
    }
    return (
      <div>
        <Typography variant='h4'>
          Enable products {account && `for ${account.account.name}`}
        </Typography>
        <div className='table-container'>
          <TableContainer component={Paper} className='table'>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Meetings</strong>
                  </TableCell>
                  <TableCell />
                </TableRow>
                {meetingsProducts.map(row => (
                  <ProductRow product={row} toggle={this.toggleProduct} withPadding />
                ))}
                {productsWithoutMeetings.map(row => (
                  <ProductRow product={row} toggle={this.toggleProduct} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    )
  }
}
