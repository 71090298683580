import React from 'react'
import ReactDOM from 'react-dom'
import './snackbar.scss'

class Snackbar extends React.Component {
  render() {
    return (
      <div className='fabe-snackbar-holder-inner'>
        <div className='fabe-snackbar'>
          {this.props.message.icon ? <i className={`fa ${this.props.message.icon}`}></i> : null}
          {this.props.message.text}
          {this.props.message.secondIcon ? (
            <i className={`fa ${this.props.message.secondIcon}`}></i>
          ) : null}
          {this.props.message.externalLink ? (
            <a href={this.props.message.externalLink} target='_blank' rel='noreferrer'>
              {this.props.message.externalLinkTitle || this.props.message.externalLink}
            </a>
          ) : null}
          {this.props.message.externalCallback ? (
            <a onClick={this.props.message.externalCallback} target='_blank'>
              {this.props.message.externalLinkTitle}
            </a>
          ) : null}
        </div>
      </div>
    )
  }
}

const animationDuration = 300
let mount = null
let opened = false
let messages = []

let init = _ => {
  mount = document.createElement('div')
  mount.className = 'fabe-snackbar-holder fabe-body'
  document.body.appendChild(mount)
  init = _ => {}
}

function hide() {
  mount.querySelector('.fabe-snackbar-holder-inner').classList.remove('show')
  mount.querySelector('.fabe-snackbar-holder-inner').classList.add('hide')
  setTimeout(() => {
    opened = false
    show()
  }, animationDuration)
}

function show() {
  if (!opened) {
    let message = messages.shift()

    if (message) {
      mount.style.display = 'block'

      if (opened) {
        ReactDOM.unmountComponentAtNode(mount)
      }

      ReactDOM.render(<Snackbar message={message} />, mount, () => {
        mount.style.marginLeft = `-${Math.round(mount.getBoundingClientRect().width) / 2}px`
        if (mount && mount.querySelector('.fabe-snackbar-holder-inner')) {
          mount.querySelector('.fabe-snackbar-holder-inner').classList.remove('hide')
          mount.querySelector('.fabe-snackbar-holder-inner').classList.add('show')
        }
      })
      opened = true
      setTimeout(hide, (message.timeout || 5000) + animationDuration)
    } else {
      mount.style.display = 'none'
    }
  }
}

export default {
  show: mgs => {
    init()
    messages.push(mgs)
    show()
  },
}
