import { nanoid } from 'nanoid'
import Snackbar from 'app/components/Snackbar'
import { api, backoffice } from '@chilipiper/service/lib/endpoint'

const apiRoot = api('/api/v1')
const currentSession = {}

/* global fetch */

function snackbarError(e = {}) {
  if (e.statusText !== 'Unauthorized') {
    const errorText = (e.responseText || '').length > 200 ? e.statusText : e.responseText
    const errorJson = e.responseJSON || {}
    const errorId = errorJson.id
    const apiError = `API Error: ${errorText || e.statusText}`
    const errorLog = errorId ? `API Error has been logged with id #${errorId}` : null
    if (errorJson.type !== 'CredentialsRequired' && errorLog) {
      Snackbar.show({ text: errorLog || apiError, icon: 'fa-heartbeat' })
    }
  }
}

const processError = error => e => {
  if (e.status !== 200 && e.status !== 0) {
    // status = 0 - manual abort
    snackbarError(e)
    error ? error(e) : null
  }
}

function execute(method, resource, callback, error, data) {
  let url = resource.indexOf('http') !== 0 ? apiRoot + resource : resource
  let headers = Object.assign(
    currentSession.token ? { Authorization: `Bearer ${currentSession.token}` } : {},
    {
      'Content-Type': 'application/json',
      'x-request-id': nanoid(),
    }
  )

  return fetch(
    url,
    Object.assign(data ? { body: JSON.stringify(data) } : {}, {
      method: method,
      mode: 'cors',
      headers: headers,
      credentials: 'include',
      withCredentials: true,
    })
  )
    .then(
      response => {
        if (response.ok) {
          response.json().then(json => {
            callback ? callback(json) : null
          })
        } else {
          processError(error)(response)
        }
        /* console.log('get'
                                    , response.status     //= > number 100–599
                                    , response.statusText //= > String
                                    , response.headers    //= > Headers
                                    , response.url        //= > String
                                ) */

        return response.status
      },
      e => {
        // console.error('[homebase]', e);
        processError(error)(e)
      }
    )
    .catch(reason => {
      console.error('-->', reason)
    })
}


export function get(resource, callback, error) {
  execute('GET', resource, callback, error)
}

export function getPlain(resource, callback, error) {
  let url = resource.indexOf('http') !== 0 ? apiRoot + resource : resource
  let headers = Object.assign(
    currentSession.token ? { Authorization: `Bearer ${currentSession.token}` } : {},
    {
      'Content-Type': 'application/json',
      'x-request-id': nanoid(),
    }
  )

  return fetch(
    url,
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      credentials: 'include',
      withCredentials: true,
    }
  )
    .then(
      response => {
        if (response.ok) {
          response.text().then(txt => callback(txt))
        } else {
          processError(error)(response)
        }
        return response.status
      },
      e => processError(error)(e)
    )
    .catch(reason => console.error('getPlain failed', reason))
}

export function session(block) {
  const init = data => {
    currentSession.token = data.token
    block()
  }

  get(backoffice('/internal/session'), init, block)
}

export const del = (resource, callback, error) => execute('DELETE', resource, callback, error)
export const put = (resource, { callback, data, error }) =>
  execute('PUT', resource, callback, error, data)
export const post = (resource, { callback, data, error }) =>
  execute('POST', resource, callback, error, data)
