import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import moment from 'moment'
import Snackbar from 'app/components/Snackbar'

export class ActiveUsersRenderer extends React.Component {
  render() {
    return <div style={{ textAlign: 'center' }}>{this.props.item.activeUsers30d}</div>
  }
}

export class EnabledUsersRenderer extends React.Component {
  render() {
    const { item } = this.props
    return (
      <div style={{ textAlign: 'center' }}>
        {item.enabledUsers === undefined ? 'N/A' : item.enabledUsers}
      </div>
    )
  }
}

export class MeetingsBookedRenderer extends React.Component {
  render() {
    return <div style={{ textAlign: 'center' }}>{this.props.item.meetingsBooked7d}</div>
  }
}

export class InboundRouterRenderer extends React.Component {
  render() {
    return <div style={{ textAlign: 'center' }}>{this.props.item.inboundRouter7d}</div>
  }
}

export class CallInitiatedRenderer extends React.Component {
  render() {
    return <div style={{ textAlign: 'center' }}>{this.props.item.callsInitiated}</div>
  }
}

export class CallConnectedRenderer extends React.Component {
  render() {
    return <div style={{ textAlign: 'center' }}>{this.props.item.callsConnected}</div>
  }
}

export class SalesforceLinkRenderer extends React.Component {
  render() {
    const link = this.props.item.salesforceLink ? (
      <a
        href={this.props.item.salesforceLink}
        target='_blank'
        style={{ cursor: 'pointer' }}
        rel='noreferrer'
      >
        <i className='fa fa-external-link' />
      </a>
    ) : null
    return <div style={{ textAlign: 'center' }}>{link}</div>
  }
}

export const nameRenderer = item => {
  let onLinkCopy = () => {
    Snackbar.show({ text: 'Impersonation link copied to clipboard' })
  }

  return (
    <div className='fabe-name-renderer'>
      <a href={item.link} target='_blank' rel='noopener noreferrer'>
        <i className='fa fa-fighter-jet' />
      </a>
      <div className='fabe-edit'>
        <a href={`/accounts/${item.id}`}>
          <i className='fa fa-pencil' />
        </a>
      </div>
      <CopyToClipboard text={item.link} onCopy={onLinkCopy}>
        <span>{item.name}</span>
      </CopyToClipboard>
    </div>
  )
}

export const adminRenderer = item => {
  let admin = item.crmAdmin || item.platformAdmin || {}
  return (
    <div className='fabe-admin-renderer'>
      <span>{admin.displayName}</span>
      <span>{admin.email}</span>
    </div>
  )
}

export const dateRenderer = item => {
  if (!item.trialExpires) {
    return <div />
  }
  let time = moment(item.trialExpires).format('MMM DD YYYY   hh:mm A')
  return (
    <div className='fabe-date-renderer'>
      <span>{time}</span>
    </div>
  )
}

export const scoreRenderer = item => {
  const score = item.score || 0
  let health = 'green'
  if (score < 70) {
    health = 'yellow'
  }
  if (score < 40) {
    health = 'red'
  }
  return (
    <div className='fabe-score'>
      <div className={`fabe-score-dot fabe-score-${health}`}>{health}</div>
      <div>{score}</div>
    </div>
  )
}

export const licensesRenderer = item => {
  const total = item.licenses.meetingsHot + item.licenses.meetingsSpicy + item.licenses.concierge
  return <div style={{ textAlign: 'center' }}>{total}</div>
}

export const createBooleanRenderer = getter => item => {
  const isTrue = getter(item)
  return <div style={{ textAlign: 'center' }}>{isTrue ? '✅' : '❌'}</div>
}

export const platformRenderer = item => (
  <div className='fabe-platforms'>
    <span>{item.calendarPlatform && item.calendarPlatform.charAt(0)}</span>
  </div>
)

export const clusterRenderer = item => (
  <div className='fabe-platforms'>
    <span>{item.cluster}</span>
  </div>
)
