import React, { useEffect, useState } from 'react'

import { Box, Flex } from '@chilipiper/design-system/lib/box/Box'
import { LabelText, Text } from '@chilipiper/design-system/lib/text'
import { TextField } from '@chilipiper/design-system/lib/text-field/TextField'
import { Button, TertiaryButton } from '@chilipiper/design-system/lib/button'
import { post } from '@chilipiper/service/lib/backoffice'
import ReactJson from 'react-json-view'
import Select from 'react-select'
import { parseQuery } from 'lib/string'
import copy from 'copy-to-clipboard'
import Snackbar from 'app/components/Snackbar'
import { existsOptions, sortingOptions, parseExistsFilterToQuery } from './helpers'

export var CrmUsageQuery = () => {
  const [query, setQuery] = useState({})
  const [page, setPage] = useState(0)
  const [sortParameters, setSortParameters] = useState([
    { value: 'durationMilli', label: 'Duration in milliseconds' },
  ])
  const [existsOptionsState, setExistOptionsState] = useState(existsOptions)
  const [notExistsOptionsState, setNotExistOptionsState] = useState(existsOptions)
  const [existsParameters, setExistsParameters] = useState([])
  const [notExistsParameters, setNotExistsParameters] = useState([])
  const [pageLimit, setPageLimit] = useState(5)
  const [queryData, setQueryData] = useState({})

  useEffect(() => {
    const queryParams = parseQuery()
    // Set filters and inputs from query params
    const inputFields = Object.keys(queryParams).reduce(
      (agg, key) => {
        if (key.includes('Exists')) {
          const filterKey = key.replace('Exists', '')
          const item = existsOptions.find(opt => opt.value === filterKey)
          return queryParams[key] === 'true'
            ? { ...agg, existsFilter: [...agg.existsFilter, item] }
            : { ...agg, notExistsFilter: [...agg.notExistsFilter, item] }
        } else {
          return {
            ...agg,
            inputsFilter: { ...agg.inputsFilter, [key]: queryParams[key] },
          }
        }
      },
      { inputsFilter: {}, existsFilter: [], notExistsFilter: [] }
    )

    setQuery(inputFields.inputsFilter)
    setExistsParameters(inputFields.existsFilter)
    setNotExistsParameters(inputFields.notExistsFilter)
  }, [])

  const setField = fieldName => event => {
    const val = event.target?.value
    setQuery(data => ({ ...data, [fieldName]: val }))
    const newExistsParams = existsParameters.filter(param => param.value !== fieldName)
    const newNotExistsParams = notExistsParameters.filter(param => param.value !== fieldName)

    setExistsParameters(newExistsParams)
    setNotExistsParameters(newNotExistsParams)
  }

  const changePage =
    (next = true) =>
    () => {
      setPage(next ? page + 1 : page - 1)
      submitQuery(next ? page + 1 : page - 1)
    }

  const changePageLimit = event => {
    setPageLimit(Number(event.target.value))
    setPage(0)
  }

  const handleExistsFilters = (setParams, setOptions, optionsState, params = []) => {
    setParams(params)
    // Disable the same options in opposite filters
    const newOptions = optionsState.map(option => {
      const sameParam = params?.find(param => param.value === option.value)
      if (sameParam) {
        return { ...option, disabled: true }
      } else {
        return { ...option, disabled: false }
      }
    })
    setOptions(newOptions)
    // Clear the values for these fields
    const valsToRemove = params?.reduce((agg, param) => {
      return { ...agg, [param.value]: '' }
    }, {})
    setQuery(data => ({ ...data, ...valsToRemove }))
  }
  const copyQuery = () => {
    const inputQueryParams = Object.keys(query).reduce((agg, key) => {
      if (query[key]) {
        return agg ? `${agg}&${key}=${query[key]}` : `${key}=${query[key]}`
      }
      return agg
    }, '')
    const existsFilterQueryParams = parseExistsFilterToQuery(existsParameters, 'true')
    const notExistsFilterQueryParams = parseExistsFilterToQuery(notExistsParameters, 'false')

    copy(
      `${window.location.origin}${window.location.pathname}?${inputQueryParams}${
        inputQueryParams && existsFilterQueryParams
          ? `&${existsFilterQueryParams}`
          : existsFilterQueryParams
      }${
        (inputQueryParams || existsFilterQueryParams) && notExistsFilterQueryParams
          ? `&${notExistsFilterQueryParams}`
          : notExistsFilterQueryParams
      }`
    )

    Snackbar.show({ text: 'Query copied to your clipboard as a url' })
  }
  const submitQuery = async (pageNumber = 0) => {
    // remove empty values
    const queryToSubmit = Object.keys(query).reduce((agg, field) => {
      return query[field] ? { ...agg, [field]: query[field] } : { ...agg }
    }, {})

    const additionalFiltersExists = existsParameters.reduce((agg, param) => {
      const name = `${param.value}Exists`
      return { ...agg, [name]: true }
    }, {})
    const additionalFiltersNotExists = notExistsParameters.reduce((agg, param) => {
      const name = `${param.value}Exists`
      return { ...agg, [name]: false }
    }, {})

    const body = {
      filters: {
        ...queryToSubmit,
        createdFrom: isNaN(Date.parse(query.createdFrom))
          ? undefined
          : new Date(query.createdFrom).toISOString(),
        createdTo: isNaN(Date.parse(query.createdTo))
          ? undefined
          : new Date(query.createdTo).toISOString(),
        ...additionalFiltersExists,
        ...additionalFiltersNotExists,
      },
      pagination: {
        page: pageNumber,
        limit: pageLimit,
      },
      sortFields: sortParameters.map(option => ({
        name: option.value,
        ascending: false,
      })),
    }
    try {
      const data = await post('admin/crm-usage', { json: body }).json()
      setQueryData(data)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Flex flexDirection='column'>
      <Flex flexWrap='wrap'>
        <Box width='23%' m={2}>
          <TextField
            name='tenantId'
            label='Tenant ID'
            value={query.tenantId}
            onChange={setField('tenantId')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='routeId'
            label='Route ID'
            value={query.routeId}
            onChange={setField('routeId')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='routerId'
            label='Router ID'
            value={query.routerId}
            onChange={setField('routerId')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='reportId'
            label='Report ID'
            value={query.reportId}
            onChange={setField('reportId')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='workspaceId'
            label='Workspace ID'
            value={query.workspaceId}
            onChange={setField('workspaceId')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='requestId'
            label='Request ID'
            value={query.requestId}
            onChange={setField('requestId')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='userId'
            label='User ID'
            value={query.userId}
            onChange={setField('userId')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='createdFrom'
            label='Created From'
            placeholder='DD/MM/YYYY'
            value={query.createdFrom}
            onChange={setField('createdFrom')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='createdTo'
            label='Created To'
            placeholder='DD/MM/YYYY'
            value={query.createdTo}
            onChange={setField('createdTo')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='durationMilliFrom'
            label='Duration in milliseconds from'
            value={query.durationMilliFrom}
            onChange={setField('durationMilliFrom')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='durationMilliTo'
            label='Duration in milliseconds to'
            value={query.durationMilliTo}
            onChange={setField('durationMilliTo')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='crmOperationType'
            label='CRM operation type'
            value={query.crmOperationType}
            onChange={setField('crmOperationType')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='crmType'
            label='CRM type'
            value={query.crmType}
            onChange={setField('crmType')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='client'
            label='Client'
            value={query.client}
            onChange={setField('client')}
          />
        </Box>
        <Box m={2} width='23%'>
          <TextField
            name='service'
            label='Service'
            value={query.service}
            onChange={setField('service')}
          />
        </Box>
      </Flex>
      <Box m={2} mt={6} w={18}>
        <TextField
          name='limit'
          label='Page limit'
          labelPlacement='outside'
          value={pageLimit}
          onChange={changePageLimit}
        />
      </Box>
      <Box m={2} mt={6} w={28}>
        <LabelText ml={3} fontSize={10}>
          Sort by (all in descending order)
        </LabelText>
        <Select
          isMulti
          id='sorting'
          label='Sorting'
          name='sorting'
          options={sortingOptions}
          value={sortParameters}
          onChange={sortOpts => {
            setSortParameters(sortOpts)
          }}
        />
      </Box>
      <Box m={2} mt={6} w={28}>
        <LabelText ml={3} fontSize={10}>
          Field to be present
        </LabelText>
        <Select
          isMulti
          id='exists'
          label='Field to be present'
          name='exists'
          options={existsOptionsState}
          value={existsParameters}
          isOptionDisabled={option => {
            return option.disabled
          }}
          onChange={existOpts => {
            handleExistsFilters(
              setExistsParameters,
              setNotExistOptionsState,
              notExistsOptionsState,
              existOpts
            )
          }}
        />
      </Box>
      <Box m={2} mt={6} w={28}>
        <LabelText ml={3} fontSize={10}>
          Field not to be present
        </LabelText>
        <Select
          isMulti
          id='not-exist'
          label='Field not to be present'
          name='not-exists'
          options={notExistsOptionsState}
          value={notExistsParameters}
          isOptionDisabled={option => {
            return option.disabled
          }}
          onChange={notExistOpts => {
            handleExistsFilters(
              setNotExistsParameters,
              setExistOptionsState,
              existsOptionsState,
              notExistOpts
            )
          }}
        />
      </Box>
      <Flex my={4}>
        <Button m={2} w={16} onClick={() => submitQuery()}>
          Submit query
        </Button>
        <Button m={2} w={16} onClick={() => copyQuery()}>
          Copy query
        </Button>
        {queryData.results && (
          <Flex ml={4} w='100%'>
            <Flex alignItems='center' mr={2}>
              <Text fontWeight={500}>
                Page Number: {page} /&nbsp;
                {Math.ceil(queryData.pagination?.total / pageLimit)}
              </Text>
            </Flex>
            <TertiaryButton m={2} onClick={changePage(false)} disabled={page === 0}>
              Previous Page
            </TertiaryButton>
            <TertiaryButton
              m={2}
              onClick={changePage()}
              disabled={
                !queryData.pagination || queryData.pagination?.total <= pageLimit * page + pageLimit
              }
            >
              Next Page
            </TertiaryButton>
          </Flex>
        )}
      </Flex>
      <ReactJson src={queryData} />
    </Flex>
  )
}
