import React from 'react'
import { post, get } from 'lib/api'
import { parseQuery } from 'lib/string'
import copy from 'copy-to-clipboard'
import Snackbar from 'app/components/Snackbar'
import { CSVLink } from 'react-csv'
import { parse } from 'json2csv/dist/json2csv.umd' // umd because esm uses import "stream" which is node specific
import Select from 'react-select'
import * as endpoint from '@chilipiper/service/lib/endpoint'

import RawSfQueryResult from './RawSfQueryResult'

var Error = ({ message }) => {
  return (
    <div className='error-notification'>
      <strong>{message}</strong>
    </div>
  )
}

class RawSfQuery extends React.PureComponent {
  state = {
    tenants: [],
    query: (parseQuery() && decodeURI(parseQuery().query)) || '',
    fields: (parseQuery() && decodeURI(parseQuery().fields)) || '',
    selectedTenantId: (parseQuery() && decodeURI(parseQuery().tenant)) || 'floatingapps.com',
    result: '{}',
    queryRunning: false,
    error: {
      message: '',
      display: false,
    },
  }

  loadTenants = () => {
    get(
      endpoint.backoffice('/admin/list-accounts'),
      result => {
        result.forEach((account, index) => {
          account.index = index
        })
        this.setState({ tenants: result })
      },
      () => {
        this.displayError('Could not load list of tenants')
      }
    )
  }

  componentDidMount() {
    this.loadTenants()
    if (Object.entries(parseQuery())) {
      this.runQuery()
    }
  }

  runQuery() {
    const { selectedTenantId, query, fields } = this.state

    this.setState({ queryRunning: true })

    const url = endpoint.backoffice('/admin/raw-salesforce-query')
    post(url, {
      data: {
        tenantId: selectedTenantId,
        query,
        fields: fields.split(/,\s*/),
      },
      callback: result => {
        if (result !== '') {
          this.setState({ result: result, queryRunning: false })
        }
      },
      error: () => {
        this.displayError('Something went wrong while executing the query')
        this.setState({ queryRunning: false })
      },
    })
  }

  copyQuery() {
    const { selectedTenantId, query, fields } = this.state
    copy(
      `${window.location.origin}${window.location.pathname}?tenant=${selectedTenantId}&query=${query}&fields=${fields}`
    )
    Snackbar.show({ text: 'Query copied to your clipboard as a url' })
  }

  displayError(message) {
    this.setState({
      error: {
        message,
        display: true,
      },
    })

    setTimeout(() => {
      this.setState({
        error: {
          message: '',
          display: false,
        },
      })
    }, 3000)
  }

  renderCSVButton() {
    try {
      const { result, selectedTenantId } = this.state
      const parsedResult = JSON.parse(result)

      const csvFileName = `${selectedTenantId}.csv`
      const csv = parse(parsedResult, {
        flatten: true,
      })

      return (
        <CSVLink data={csv} separator=',' className='csv-button' filename={csvFileName}>
          Export as CSV
        </CSVLink>
      )
    } catch (err) {
      return null
    }
  }

  renderActionButtons() {
    return (
      <div className='fabe-query-button-container'>
        <button type='button' onClick={() => this.runQuery()}>
          Run Query
        </button>
        <button type='button' onClick={() => this.copyQuery()}>
          Copy Query
        </button>
        {this.renderCSVButton()}
      </div>
    )
  }

  render() {
    const { error, selectedTenantId, tenants, fields, query, queryRunning, result } = this.state

    return (
      <div>
        {error.display && <Error message={error.message} />}
        <div
          style={{
            height: '70px',
            padding: '10px',
            fontSize: '15px',
            marginTop: '20px',
          }}
        >
          <Select
            defaultValue={selectedTenantId}
            onChange={option => {
              this.setState({ selectedTenantId: option.value })
            }}
            options={tenants.map(tenant => ({
              value: tenant.id,
              label: tenant.id,
            }))}
          />
        </div>
        Fields (Example: <b>Email, FirstName</b>)
        <textarea
          style={{
            width: '100%',
            height: '100px',
            padding: '10px',
            fontSize: '15px',
            marginBottom: '20px',
          }}
          value={fields}
          onChange={e => this.setState({ fields: e.target.value })}
        />
        Query (Example:{' '}
        <b>
          Select Id,Email, FirstName From Contact Where Email Like Where Email Like &apos;%@%&apos;
          ORDER BY CreatedDate DESC LIMIT 1
        </b>
        )
        <textarea
          style={{
            width: '100%',
            height: '100px',
            padding: '10px',
            fontSize: '15px',
            marginBottom: '20px',
          }}
          value={query}
          onChange={e => this.setState({ query: e.target.value })}
        />
        {queryRunning ? <div className='query-loader' /> : this.renderActionButtons()}
        <RawSfQueryResult result={result} />
      </div>
    )
  }
}

export default RawSfQuery
