import React from 'react'
import CurrentUserProfile from 'lib/profile/CurrentUserProfile'
import session from 'lib/session'
import ProfileActions from './ProfileActions'
import './actionbar.css'

export default () => (
  <header className='actionbar'>
    <a href='/'>
      <div className='site-logo' />
    </a>
    <aside className='profile'>
      <div className='user'>
        <CurrentUserProfile />
        <span>
          <a>{session.fullName()}</a>
        </span>
        <ProfileActions />
      </div>
    </aside>
  </header>
)
