import React from 'react'
import PropTypes from 'prop-types'
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import { Provider as ThemeProvider } from '@chilipiper/design-system/lib/ThemeProvider'
import { api } from '@chilipiper/service/lib/backoffice'
import { secrets } from '@chilipiper/config'
import { SWRConfig } from 'swr'
import ActionBar from './actionbar/ActionBar'
import './style.css'

class App extends React.Component {
  state = {
    loading: true,
    menuOpen: false,
    expired: undefined,
  }

  static propTypes = {
    children: PropTypes.object,
  }

  childContextTypes = {
    muiTheme: PropTypes.object,
  }

  changeTitle = () => {
    document.title = 'Chili Piper - X'
  }

  componentDidMount() {
    this.changeTitle()
  }

  toggleMenuOpen() {
    this.setState({ menuOpen: !this.state.menuOpen }, () => {
      if (localStorage) {
        localStorage.setItem('chili-menu-open', this.state.menuOpen ? 'opened' : 'closed')
      }
    })
  }

  closeMenu() {
    if (this.state.menuOpen) {
      this.setState({ menuOpen: false })
    }
  }

  renderCenter() {
    return (
      <section className='middle'>
        <main className='content'>{this.props.children}</main>
      </section>
    )
  }

  render() {
    const link = `https://chrome.google.com/webstore/detail/${secrets.extensionId}`

    const lightMuiTheme = getMuiTheme(lightBaseTheme)
    const center = this.renderCenter()
    return (
      <SWRConfig
        value={{
          refreshInterval: 3000,
          fetcher: (resource, init) => {
            return api(resource.replace(/^\/+/, ''), init).json()
          },
        }}
      >
        <ThemeProvider>
          <MuiThemeProvider muiTheme={lightMuiTheme}>
            <div className='layout'>
              <link rel='chrome-webstore-item' href={link} />
              <ActionBar menuOpen={this.state.menuOpen} handleClick={this.toggleMenuOpen} />
              {center}
            </div>
          </MuiThemeProvider>
        </ThemeProvider>
      </SWRConfig>
    )
  }
}

export default App
