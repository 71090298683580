import React from 'react'

import { Box, Flex } from '@chilipiper/design-system/lib/box/Box'
import { Text } from '@chilipiper/design-system/lib/text'

export var AccountDetails = ({ account }) => {
  if (!account) {
    return null
  }

  return (
    <Flex flexDirection='column'>
      <Flex>
        <Text fontWeight={600}>Account: </Text>
        <Text>{account.name}</Text>
      </Flex>
      <Flex mt={1}>
        <Text fontWeight={600}>Cluster: </Text>
        <Text>{account.cluster}</Text>
      </Flex>
      <Flex flexDirection='column' mt={1}>
        <Text fontWeight={600}>Connections: </Text>

        {Object.entries(account.connections).map(([key, value]) => (
          <Box key={key}>
            <Flex>
              <Box>
                <Text fontWeight={600}>Name: </Text>
                <Text>{key}</Text>
              </Box>
              <Box ml={2}>
                <Text fontWeight={600}>Status: </Text>
                <Text>{value.status}</Text>
              </Box>
            </Flex>
          </Box>
        ))}
      </Flex>
    </Flex>
  )
}
