import React from 'react'
import * as endpoint from '@chilipiper/service/lib/endpoint'
import { FlatButton, FontIcon } from 'material-ui'

class ProfileActions extends React.Component {
  state = {
    open: false,
  }

  changeState = () => {
    this.setState({ open: !this.state.open })
  }

  handleLogout() {
    window.location.href = endpoint.api('/logout')
  }

  renderMenu() {
    return (
      <div className='profile-actions'>
        <FlatButton className='logout-button' onClick={this.handleLogout}>
          <FontIcon className='logout-icon' />
          <span className='logout-label'>Logout</span>
        </FlatButton>
      </div>
    )
  }

  render() {
    let s = this.state
    let clazz = s.open ? 'arrow-down' : 'arrow-up'

    return (
      <div className='profile-arrow'>
        <div className={clazz} onClick={this.changeState} />
        {s.open ? this.renderMenu() : null}
      </div>
    )
  }
}

export default ProfileActions
