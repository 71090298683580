import React from 'react'
import ReactJson from 'react-json-view'

export default class QueryResult extends React.PureComponent {
  render() {
    const { count, result } = this.props
    if (count) {
      return (
        <div
          style={{
            height: '70px',
            padding: '10px',
            fontSize: '15px',
            marginTop: '20px',
          }}
        >
          {Number.isInteger(parseInt(result, 0)) ? result : result.length}
        </div>
      )
    }
    return (
      <>
        {result.length > 0 && <p>Found {result.length} results</p>}
        <ReactJson src={result} />
      </>
    )
  }
}
