export const hashCode = str => {
  let hash = 0,
    i,
    chr,
    len
  if (!str || str.length == 0) {
    return hash
  }
  for (i = 0, len = str.length; i < len; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }

  return hash < 0 ? ~hash : hash
}

export const parseQuery = function () {
  let str = window.location.hash.split('?')[1] || window.location.search
  let params = {}

  str.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
    params[$1] = $3
  })
  return params
}
