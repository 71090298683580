import React from 'react'
import Select from 'react-select'
import copy from 'copy-to-clipboard'
import { del, get, getPlain, post, put } from 'lib/api'
import * as endpoint from '@chilipiper/service/lib/endpoint'
import { endpoint as configEndpoint } from '@chilipiper/config'
import { parseQuery } from 'lib/string'
import { CSVLink } from 'react-csv'
import { parse, transforms } from 'json2csv/dist/json2csv.umd' // umd because esm uses import "stream" which is node specific
import Snackbar from 'app/components/Snackbar'
import pick from 'lodash/pick'
import merge from 'lodash/merge'

import Action, { DateRangeAction } from './Action'
import QueryResult from './QueryResult'
import { QueryBuilder } from './QueryBuilder'
import { AccountDetails } from './AccountDetails'
import './query.scss'

const flattenRow = transforms.flatten()

const tabs = {
  raw: 'RAW_DETAILS',
  actions: 'ACTIONS',
}

var Error = ({ message }) => {
  return (
    <div className='error-notification'>
      <strong>{message}</strong>
    </div>
  )
}

class Query extends React.PureComponent {
  state = {
    query: (parseQuery() && parseQuery().query && decodeURIComponent(parseQuery().query)) || '{}',
    accounts: [],
    selectedTab: tabs.raw,
    selectedAccount: parseQuery() && parseQuery().tenant,
    selectedCollection: parseQuery() && parseQuery().collection,
    collections: [],
    parsedResult: [],
    order: parseInt((parseQuery() && parseQuery().order) || -1, 10),
    limit: parseInt((parseQuery() && parseQuery().limit) || 10, 10),
    count: (parseQuery() && parseQuery().count && parseQuery().count === true) || false,
    queryRunning: false,
    error: {
      message: '',
      display: false,
    },
    collectionProps: {},
  }

  componentWillMount() {
    getPlain(
      endpoint.backoffice('/admin/list-accounts-minimal'),
      result => {
        let list = result.split('\n')
        list.sort((a, b) => a.localeCompare(b))
        this.setState({ accounts: list })
      },
      () => {
        this.displayError('Account list loading failed')
      }
    )
    get(
      endpoint.backoffice(`/admin/accounts/${this.state.selectedAccount}`),
      account => {
        this.setState({ accountDetails: account }, () => {
          if (Object.entries(parseQuery()).length) {
            this.loadCollections()
            this.runQuery()
          }
        })
      },
      () => {
        this.displayError('Could not load account details')
      }
    )
  }

  updateQueryParam(name, value) {
    const p = new URLSearchParams(window.location.search)
    p.set(name, value)
    const url = `${window.location.origin}${window.location.pathname}?${p.toString()}`
    history.replaceState(
      {
        path: url,
      },
      '',
      url
    )
  }

  updateQueryCollection = data => {
    const { query, selectedAccount, selectedCollection } = this.state
    if (selectedAccount && selectedCollection) {
      post(endpoint.backoffice(`/admin/query/${selectedAccount}/${selectedCollection}`), {
        data: {
          query: query,
          updateQuery: JSON.stringify(data),
        },
        callback: () => {
          Snackbar.show({ text: 'Collection updated' })
          this.runQuery()
        },
      })
    }
  }

  exportMeetingsPerQueue = (start, end) => {
    const { selectedAccount } = this.state
    post(endpoint.backoffice(`/admin/export-meetings-per-queue`), {
      data: { tenantId: selectedAccount, start, end },
      callback: () => {
        Snackbar.show({ text: 'Export initiated' })
      },
    })
  }

  exportMeetingsCount = (start, end) => {
    const { selectedAccount } = this.state
    post(endpoint.backoffice(`/admin/export-meetings-count`), {
      data: { tenantId: selectedAccount, start, end },
      callback: () => {
        Snackbar.show({ text: 'Export initiated' })
      },
    })
  }

  loadCollections = () => {
    const { selectedAccount } = this.state
    if (selectedAccount) {
      get(
        endpoint.backoffice(`/admin/collections/${selectedAccount}`),
        result => {
          this.setState({ collections: result })
        },
        () => {
          const errorMessage = `Could not load collections for ${selectedAccount} `
          this.displayError(errorMessage)
        }
      )
    }
  }

  onAddFakeSf = user => {
    const { selectedAccount } = this.state
    put(
      endpoint.backoffice(
        `/admin/user/add-fake-sf?tenantId=${selectedAccount}&userId=${user._id.$oid}`
      ),
      {
        callback: () => {
          Snackbar.show({ text: `User ${user.displayName} updated` })
          this.runQuery()
        },
      }
    )
  }

  onRemoveSalesforceConnection = (user, rawConnectionId) => {
    const { selectedAccount } = this.state

    const connectionId = rawConnectionId === '' ? null : rawConnectionId

    post(
      endpoint.backoffice(
        `/admin/user/remove-sf?tenantId=${selectedAccount}&userId=${user._id.$oid}`
      ),
      {
        data: { connectionId },
        callback: () => {
          Snackbar.show({ text: `User ${user.displayName} updated` })
          this.runQuery()
        },
      }
    )
  }

  selectPrimarySalesforce = (user, connectionId) => {
    const { selectedAccount } = this.state
    put(
      endpoint.backoffice(
        `/admin/user/select-primary-salesforce?tenantId=${selectedAccount}&userId=${user._id.$oid}`
      ),
      {
        data: { connectionId },
        callback: () => {
          Snackbar.show({
            text: `Salesforce connection '${connectionId}' selected`,
          })
          this.runQuery()
        },
      }
    )
  }

  onSetDefaultBooker = (user, queueId) => {
    const { selectedAccount } = this.state
    put(
      endpoint.backoffice(
        `/admin/user/set-default-booker?tenantId=${selectedAccount}&userId=${user._id.$oid}`
      ),
      {
        data: { queueId },
        callback: () => {
          Snackbar.show({ text: `Queue '${queueId}' updated` })
          this.runQuery()
        },
      }
    )
  }

  onRemoveGoogleConnection = user => {
    const { selectedAccount } = this.state
    put(
      endpoint.backoffice(
        `/admin/user/remove-google-connection?tenantId=${selectedAccount}&userId=${user._id.$oid}`
      ),
      {
        callback: () => {
          Snackbar.show({ text: `User ${user.displayName} updated` })
          this.runQuery()
        },
      }
    )
  }

  onRemoveMsConnection = user => {
    const { selectedAccount } = this.state
    put(
      endpoint.backoffice(
        `/admin/user/remove-ms-connection?tenantId=${selectedAccount}&userId=${user._id.$oid}`
      ),
      {
        callback: () => {
          Snackbar.show({ text: `User ${user.displayName} updated` })
          this.runQuery()
        },
      }
    )
  }

  deleteUserMigration = user => {
    const { selectedAccount } = this.state
    del(
      endpoint.backoffice(
        `/admin/user/migration?tenantId=${selectedAccount}&userId=${user._id.$oid}`
      ),
      {
        callback: () => {
          Snackbar.show({ text: `User ${user.displayName} migration deleted` })
          this.runQuery()
        },
      }
    )
  }

  deleteUser = user => {
    const { selectedAccount } = this.state
    del(endpoint.backoffice(`/admin/user?tenantId=${selectedAccount}&userId=${user._id.$oid}`), {
      callback: () => {
        Snackbar.show({ text: `User ${user.displayName} deleted` })
        this.runQuery()
      },
    })
  }

  toggleMasterAdmin = user => {
    const { selectedAccount } = this.state
    const isAdmin = !user.isAdmin
    post(
      endpoint.backoffice(`/admin/user/role?tenantId=${selectedAccount}&userId=${user._id.$oid}`),
      {
        data: { isAdmin },
        callback: () => {
          let text = isAdmin
            ? `User ${user.displayName} is now master admin`
            : `User ${user.displayName} is no longer master admin`
          Snackbar.show({ text: text })
          this.runQuery()
        },
      }
    )
  }

  setProfilePicture = (user, url) => {
    const { selectedAccount } = this.state
    post(
      endpoint.backoffice(
        `/admin/user/profile-picture?tenantId=${selectedAccount}&userId=${user._id.$oid}`
      ),
      {
        data: { picture: url !== '' ? url : null },
        callback: () => Snackbar.show({ text: 'Profile picture updated' }),
      }
    )
  }

  getCollectionProps = result => {
    try {
      if (Array.isArray(result) && result.length) {
        const props = new Set()
        result.forEach(row => Object.keys(row).forEach(key => props.add(key)))
        return [...props].reduce((acc, prop) => {
          acc[prop] = true
          return acc
        }, {})
      }
      return {}
    } catch (error) {
      return {}
    }
  }

  pickCheckedProps = collectionProps => {
    return Object.entries(collectionProps).reduce((acc, [collectionProp, checked]) => {
      checked && acc.push(collectionProp)
      return acc
    }, [])
  }

  onKeyDown = e => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      this.runQuery()
    }
  }

  runQuery = () => {
    const { selectedAccount, accountDetails, selectedCollection, query, order, limit, count } = this.state

    this.setState({ queryRunning: true })

    let requestUrl = endpoint.backoffice(`/admin/query/${selectedAccount}/${selectedCollection}`)

    if (accountDetails.account.cluster === 'canary') {
      requestUrl = requestUrl.replace(
        configEndpoint.backoffice(),
        process.env.REACT_APP_BACKOFFICE_RESOURCE_CANARY
      )
    }

    post(requestUrl, {
      data: {
        query,
        order,
        limit,
        count,
      },
      callback: result => {
        const parsedResult = result && (result.entries || result.count)
        if (result && result !== '') {
          this.setState({
            selectedTab: tabs.raw,
            parsedResult,
            queryRunning: false,
            collectionProps: this.getCollectionProps(parsedResult),
          })
        } else {
          this.displayError('Something went wrong while executing the query')
        }
      },
      error: () => {
        this.displayError('Something went wrong while executing the query')
        this.setState({ queryRunning: false })
      },
    })
  }

  copyQuery() {
    const { selectedAccount, selectedCollection, query, order, limit, count } = this.state
    copy(
      `${window.location.origin}${
        window.location.pathname
      }?tenant=${selectedAccount}&collection=${selectedCollection}&query=${encodeURIComponent(
        query
      )}&order=${order}&limit=${limit}&count=${count}`
    )
    Snackbar.show({ text: 'Query copied to your clipboard as a url' })
  }

  displayError(message) {
    this.setState({
      error: {
        message,
        display: true,
      },
    })

    setTimeout(() => {
      this.setState({
        error: {
          message: '',
          display: false,
        },
      })
    }, 3000)
  }

  renderCSVButton() {
    const { selectedAccount, selectedCollection, collectionProps, parsedResult } = this.state
    if (!parsedResult.length) {
      return null
    }

    const csvFileName = `${selectedAccount}_${selectedCollection}.csv`
    const propsToPick = this.pickCheckedProps(collectionProps)
    const mergedResultSample = parsedResult.reduce((acc, next) => {
      return merge(acc, next)
    }, {})
    const pickedResultRow = pick(mergedResultSample, propsToPick)
    const flattenedPickedRow = flattenRow(pickedResultRow)
    const csv = parse(parsedResult, { fields: Object.keys(flattenedPickedRow), flatten: false })

    return (
      <div className='fabe-csv-container'>
        <CSVLink data={csv} separator=',' className='csv-button' filename={csvFileName}>
          Export as CSV
        </CSVLink>
        <ul className='fabe-collection-container'>
          {Object.entries(collectionProps).map(([collectionProp, checked]) => (
            <li className='fabe-collection-prop'>
              <label htmlFor={collectionProp}>
                <input
                  type='checkbox'
                  id={collectionProp}
                  checked={checked}
                  onChange={() =>
                    this.setState({
                      collectionProps: {
                        ...collectionProps,
                        [collectionProp]: !checked,
                      },
                    })
                  }
                />
                {collectionProp}
              </label>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderActionButtons() {
    return (
      <div className='fabe-query-button-container'>
        <button type='button' onClick={() => this.runQuery()}>
          Run Query
        </button>
        <button type='button' onClick={() => this.copyQuery()}>
          Copy Query
        </button>
        {this.renderCSVButton()}
      </div>
    )
  }

  changeTab = (e, tab) => {
    e.preventDefault()
    this.setState({ selectedTab: tab })
  }

  renderQueuesActions = () => {
    const { parsedResult } = this.state

    return (
      <div className='query-actions'>
        {parsedResult.length === 1 && (
          <Action
            handler={value =>
              this.updateQueryCollection({
                'onlineSettings.linkName': value,
              })
            }
            title='Update Booking Link'
            subtitle={`What's the new booking link?`}
          />
        )}
        <DateRangeAction
          title='Export #meetings/queue'
          handler={(start, end) => this.exportMeetingsPerQueue(start, end)}
        />
      </div>
    )
  }

  renderUserActions() {
    const { parsedResult } = this.state
    const user = parsedResult[0]

    if (parsedResult.length !== 1) {
      return null
    }

    return (
      <div className='query-actions'>
        <Action
          handler={value =>
            this.updateQueryCollection({
              displayName: value,
            })
          }
          title='Update name'
          subtitle={`What's the new display name?`}
        />
        <Action
          handler={value =>
            this.updateQueryCollection({
              linkName: value,
            })
          }
          title='Update Booking Link'
          subtitle={`What's the new booking link?`}
        />
        <Action
          handler={value =>
            this.updateQueryCollection({
              email: value,
            })
          }
          title='Update User Email'
          inputType='email'
          subtitle={`What's the new email?`}
        />
        <Action
          handler={value =>
            this.updateQueryCollection({
              'connections.salesforce.active': value,
            })
          }
          title='Update salesforce active state'
          inputType='checkbox'
          subtitle={`What's the new salesroce active state?`}
        />
        {!(user.connections || {}).salesforce && (
          <Action
            title='Insert Fake SF User'
            toggleable={false}
            handler={() => this.onAddFakeSf(user)}
          />
        )}
        {user?.connections?.google && (
          <Action
            title='Remove Google Connection'
            toggleable={false}
            handler={() => this.onRemoveGoogleConnection(user)}
          />
        )}
        {(user.connections || {}).microsoft && (
          <Action
            title='Remove MS Connection'
            toggleable={false}
            handler={() => this.onRemoveMsConnection(user)}
          />
        )}
        {user.connections?.otherSalesforce?.length > 0 && (
          <Action
            title='Select Primary Salesforce Connection'
            toggleable={true}
            subtitle='Salesforce Connection ID'
            handler={connectionId => this.selectPrimarySalesforce(user, connectionId)}
          />
        )}
        {(user.connections?.salesforce || user.connections?.otherSalesforce) && (
          <Action
            title='Remove Salesforce Connection'
            toggleable={true}
            subtitle='Connection ID (leave empty for all)'
            required={false}
            handler={connectionId =>
              this.onRemoveSalesforceConnection(parsedResult[0], connectionId)
            }
          />
        )}
        <Action
          title='Set as a default queue booker'
          toggleable={true}
          subtitle='Queue ID'
          handler={queueId => this.onSetDefaultBooker(user, queueId)}
        />
        <Action
          title='Toggle master admin'
          toggleable={false}
          handler={() => this.toggleMasterAdmin(user)}
        />
        <Action
          title='Set profile picture'
          subtitle='URL (leave empty to unset)'
          required={false}
          handler={value => this.setProfilePicture(user, value)}
        />
        <Action title='Delete user' toggleable={false} handler={() => this.deleteUser(user)} />
        <Action
          title='Delete user migration'
          toggleable={false}
          handler={() => this.deleteUserMigration(user)}
        />
      </div>
    )
  }

  renderReportsActions = () => {
    const { parsedResult } = this.state

    return (
      <div className='query-actions'>
        {parsedResult.length === 1 && (
          <>
            <Action
              handler={value =>
                this.updateQueryCollection({
                  remindersToSkip: value.split(',').map(s => s.trim()),
                })
              }
              title='Update reminders to skip.  comma separated'
              subtitle='What are the ids of reminders to skip?'
            />
            <Action
              handler={value =>
                this.updateQueryCollection({
                  status: value ? 'cancelled' : '',
                })
              }
              title='Update cancelled status'
              inputType='checkbox'
              subtitle={`What's the cancell status?`}
            />
          </>
        )}
        <DateRangeAction
          title='Export meetings count'
          handler={(start, end) => this.exportMeetingsCount(start, end)}
        />
      </div>
    )
  }

  renderSetupStateActions = () => {
    return (
      <div className='query-actions'>
        <Action
          title='Force full sync'
          inputType='button'
          handler={() => this.updateQueryCollection({ historySyncNeeded: true })}
        />
      </div>
    )
  }

  renderActions = selectedCollection => {
    switch (selectedCollection) {
      case 'users':
        return this.renderUserActions()
      case 'booker.queues':
        return this.renderQueuesActions()
      case 'booker.reports':
        return this.renderReportsActions()
      case 'booker.states':
        return this.renderSetupStateActions()
      default:
        return
    }
  }

  render() {
    const {
      error,
      selectedAccount,
      accounts,
      selectedCollection,
      collections,
      count,
      order,
      query,
      queryRunning,
      limit,
      parsedResult,
      sortBy,
      selectedTab,
    } = this.state

    return (
      <div>
        {error.display && <Error message={error.message} />}
        <div className='inputs-wrapper'>
          <Select
            defaultValue={selectedAccount}
            defaultInputValue={selectedAccount}
            onChange={option => {
              this.updateQueryParam('tenant', option.value)
              get(
                endpoint.backoffice(`/admin/accounts/${option.value}`),
                account => {
                  this.setState({ selectedAccount: option.value, accountDetails: account }, () => {
                    this.loadCollections()
                    this.runQuery()
                  })
                },
                () => {
                  this.displayError('Could not load account details')
                }
              )
            }}
            options={accounts.map(account => ({
              value: account,
              label: account,
            }))}
            className='select'
          />
          <Select
            defaultValue={selectedCollection}
            defaultInputValue={selectedCollection}
            onChange={option => {
              this.updateQueryParam('collection', option.value)
              this.setState({
                selectedCollection: option.value,
              })
            }}
            options={collections.map(collection => ({
              value: collection,
              label: collection,
            }))}
            className='select'
          />
          <input
            placeholder='Sort by'
            value={sortBy}
            onChange={e => this.setState({ sortBy: e.target.value })}
          />
          <Select
            defaultValue={order}
            defaultInputValue={order === 1 ? 'Asc' : 'Desc'}
            onChange={option => {
              this.updateQueryParam('order', parseInt(option.value, 10))
              this.setState({
                order: parseInt(option.value, 0),
              })
            }}
            options={[
              {
                value: -1,
                label: 'Desc',
              },
              {
                value: 1,
                label: 'Asc',
              },
            ]}
            className='select'
          />
          <span>Limit</span>
          <input
            type='number'
            value={limit}
            onChange={e => {
              this.updateQueryParam('limit', e.target.value)
              this.setState({
                limit: parseInt(e.target.value, 0),
              })
            }}
          />

          <span>Count</span>
          <input type='checkbox' value={count} onChange={() => this.setState({ count: !count })} />
        </div>
        <AccountDetails account={this.state.accountDetails?.account} />
        <QueryBuilder
          collectionType={selectedCollection}
          query={query}
          onChange={query => this.setState({ query })}
        />
        <textarea
          value={query}
          onChange={e => this.setState({ query: e.target.value })}
          onKeyDown={this.onKeyDown}
        />
        {queryRunning ? <div className='query-loader' /> : this.renderActionButtons()}
        <div className='actions-container'>
          <a
            className={selectedTab === tabs.raw ? 'active' : ''}
            onClick={e => this.changeTab(e, tabs.raw)}
          >
            Raw details
          </a>{' '}
          |{' '}
          <a
            className={selectedTab === tabs.actions ? 'active' : ''}
            onClick={e => this.changeTab(e, tabs.actions)}
          >
            Actions
          </a>
          {selectedTab === tabs.raw && <QueryResult result={parsedResult} count={count} />}
          {selectedTab === tabs.actions && this.renderActions(selectedCollection)}
        </div>
      </div>
    )
  }
}

export default Query
