let session = { user: {} }

export default {
  create: data => {
    session = data
  },

  email: () => session.gmail,
  token: () => session.token,
  fullName: () => session.displayName || `${session.platformFirstName} ${session.platformLastName}`,
  getUser: () => session.user,
}
