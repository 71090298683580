import React, { useState } from 'react'

import { TextField } from '@chilipiper/design-system/lib/text-field/TextField'
import { Box, Flex } from '@chilipiper/design-system/lib/box/Box'

function fieldMapper(collectionType) {
  switch (collectionType) {
    case 'booker.reports':
      return [
        ['Email', 'prospectInfo.email'],
        ['ID', '_id'],
        ['CRM ID', 'prospectInfo._id'],
      ]

    case 'booker.routes':
      return [
        ['Email', 'values.Email'],
        ['ID', '_id'],
      ]

    case 'booker.routes.debug':
      return [
        ['Email', 'values.Email'],
        ['ID', '_id'],
      ]

    case 'users':
      return [
        ['Email', 'email'],
        ['ID', '_id'],
      ]

    case 'email':
      return [['Email', 'email']]

    case 'queue':
      return [
        ['Name', 'name'],
        ['ID', '_id'],
      ]

    case 'reminders':
      return [['ID', '_id']]

    case 'booker.templates':
      return [
        ['Name', 'name'],
        ['ID', '_id'],
      ]

    default:
      return []
  }
}

var QueryField = ({ name, label, onChange, value }) => {
  const [state, setState] = useState(value)

  return (
    <TextField
      name={name}
      label={label}
      key={name}
      value={state}
      onChange={e => {
        setState(e.currentTarget.value)
        onChange(name, e.currentTarget.value)
      }}
    />
  )
}

function mapToObj(m) {
  return Array.from(m).reduce((obj, [key, value]) => {
    obj[key] = value
    return obj
  }, {})
}

function getInitialValue(query, name) {
  try {
    const nextQuery = new Map(Object.entries(JSON.parse(query)))
    const value = nextQuery.get(name) || ''

    const matchId = value.match(/Object\((.+)\)/)

    return matchId != null ? matchId[1] : value
  } catch (err) {
    return ''
  }
}

export var QueryBuilder = ({ collectionType, query = '{}', onChange }) => {
  const fields = fieldMapper(collectionType)

  const updateQueryPreview = (name, value) => {
    try {
      const nextQuery = new Map(Object.entries(JSON.parse(query)))
      nextQuery.set(name, name === '_id' ? `Object(${value})` : value)

      const validKeys = new Set(fields.map(([_, key]) => key))

      nextQuery.forEach((value, key, map) => {
        if (!value || value === 'Object()' || !validKeys.has(key)) {
          nextQuery.delete(key)
        }
      })

      onChange(JSON.stringify(mapToObj(nextQuery), null, 2))
    } catch (err) {}
  }

  return (
    <Flex my={2}>
      {fields.map(([label, name]) => (
        <Box key={name} mx={2}>
          <QueryField
            name={name}
            label={label}
            value={getInitialValue(query, name)}
            onChange={updateQueryPreview}
          />
        </Box>
      ))}
    </Flex>
  )
}
