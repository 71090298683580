import React, { useState } from 'react'
import moment from 'moment'

const Action = ({
  toggleable = true,
  handler,
  title,
  subtitle,
  inputType = 'text',
  required = true,
}) => {
  const [display, setDisplay] = useState(false)
  const [value, setValue] = useState('')
  const [checkedValue, setCheckedValue] = useState(false)
  return (
    <div className='action-container'>
      <button
        type='button'
        onClick={e => {
          toggleable ? setDisplay(!display) : handler(e)
        }}
      >
        {title}
      </button>
      {display && (
        <div>
          <p>{subtitle}</p>
          <form
            onSubmit={e => {
              e.preventDefault()
              handler(inputType !== 'checkbox' ? value : checkedValue)
              setValue('')
            }}
          >
            <input
              value={inputType !== 'checkbox' ? value : checkedValue}
              type={inputType}
              required={inputType !== 'checkbox' && required}
              onChange={e =>
                inputType !== 'checkbox'
                  ? setValue(e.target.value)
                  : setCheckedValue(e.target.checked)
              }
            />
            <button type='submit'>Apply</button>
          </form>
        </div>
      )}
    </div>
  )
}

export default Action

export var DateRangeAction = ({ title, submitText = 'Export', handler }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [start, setStart] = useState(() => moment().subtract(7, 'days').toISOString())
  const [end, setEnd] = useState(() => moment().toISOString())

  const toggle = () => setIsExpanded(!isExpanded)
  const onSubmit = e => {
    e.preventDefault()
    handler(moment(start), moment(end))
  }

  return (
    <div className='action-container'>
      <button type='button' onClick={toggle}>
        {title}
      </button>
      {isExpanded && (
        <p>
          <form onSubmit={onSubmit}>
            <input
              type='text'
              placeholder='From'
              value={start}
              onChange={e => setStart(e.target.value)}
            />
            <input
              type='text'
              placeholder='To'
              value={end}
              onChange={e => setEnd(e.target.value)}
            />
            <button type='submit'>{submitText}</button>
          </form>
        </p>
      )}
    </div>
  )
}
