import React from 'react'
import _ from 'lodash'

class Column extends React.Component {
  state = {
    asc: true,
  }

  componentDidMount() {}

  onColumnClick = event => {
    let target = event.currentTarget
    this.setState({ asc: !this.state.asc }, () => {
      let orderEvent = new CustomEvent('order', {
        detail: {
          field: this.props.field,
          asc: this.state.asc,
        },
        bubbles: true,
        cancelable: true,
      })

      target.dispatchEvent(orderEvent)
    })
  }

  renderItem = (item, index) => {
    let props = this.props
    const { field, getField } = props
    const datum = props.data[index]
    let renderer = props.renderer ? (
      props.renderer(datum, index)
    ) : (
      <span>{getField ? getField(datum, index) : datum[field].toString()}</span>
    )
    return (
      <div className='fabe-datagrid-cell fabe-column-row' key={`fabe-column-row${index}`}>
        {renderer}
      </div>
    )
  }

  renderTotal = () => {
    if (!this.props.total) {
      return null
    }
    let total = _.sumBy(this.props.data, this.props.field || this.props.getField)
    return (
      <div className='fabe-column-summary'>
        <span>{total}</span>
      </div>
    )
  }

  render() {
    let props = this.props
    let header = props.headerRenderer ? props.headerRenderer() : <span>{props.header}</span>
    return (
      <div className={`${'fabe-datagrid-column fabe-column-'}${props.title}`}>
        <div
          className={`${'fabe-datagrid-cell fabe-column-title fabe-column-'}${props.title}`}
          title={typeof props.header === 'string' ? props.header : undefined}
          onClick={this.onColumnClick}
        >
          {header}
        </div>
        {props.data.map(this.renderItem)}
        {props.grid.totalPlacement === 'bottom' ? this.renderTotal() : null}
      </div>
    )
  }
}

export default Column
