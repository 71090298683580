import React from 'react'
import { Link } from 'react-router'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableHeadCell,
} from '@chilipiper/design-system/lib/sticky-table'
import { Provider } from '@chilipiper/design-system/lib/ThemeProvider'
import { TableHeader } from '@chilipiper/design-system/lib/table-header/TableHeader'
import * as endpoint from '@chilipiper/service/lib/endpoint'
import { post } from 'lib/api'
import { useScrollableTable } from '@chilipiper/design-system/lib/sticky-table/useScrollableTable'
import {
  ActiveUsersRenderer,
  EnabledUsersRenderer,
  MeetingsBookedRenderer,
  SalesforceLinkRenderer,
  InboundRouterRenderer,
  CallInitiatedRenderer,
  CallConnectedRenderer,
  dateRenderer,
  scoreRenderer,
  licensesRenderer,
  platformRenderer,
  clusterRenderer,
  createBooleanRenderer,
  nameRenderer,
  adminRenderer,
} from './AccountsRenderers'
import './accounts.scss'

const PLACEHOLDER_ACCOUNT_ID = 'PLACEHOLDER_ACCOUNT_ID'

const placeholderAccount = {
  id: PLACEHOLDER_ACCOUNT_ID,
  licenses: {},
  stripe: {},
}

const fetchAccountsRequest = params =>
  new Promise(resolve =>
    post(endpoint.backoffice('/admin/list-accounts'), {
      data: params,
      callback: resolve,
    })
  )

export const Accounts = () => {
  const {
    tableState: { total },
    tableScrollableContainerRef,
    resultsToShow: visibleAccounts,
    onSearch,
  } = useScrollableTable({
    fetchResultsRequest: fetchAccountsRequest,
    placeHolderResult: placeholderAccount,
  })

  return (
    <Provider>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link to='/accounts-old'>Old view</Link>
      </div>
      <TableHeader itemsCount={total} onSearch={onSearch} />
      <div className='fabe-accounts-list' style={{ height: 'calc(100vh - 67px - 48px)' }}>
        <Table
          wrapperRef={tableScrollableContainerRef}
          border='header'
          sticky={{
            leftStickyColumnCount: 2,
            stickyHeaderCount: 1,
          }}
        >
          <TableHead>
            <TableRow>
              <TableHeadCell>#</TableHeadCell>
              <TableHeadCell width={280} title='Company' />
              <TableHeadCell width={70} title='Hot' />
              <TableHeadCell width={70} title='Spicy' />
              <TableHeadCell width={70} title='Free' />
              <TableHeadCell title='Concierge' />
              <TableHeadCell title='Live' />
              <TableHeadCell title='Stripe Org' />
              <TableHeadCell title='Salesforce Account' />
              <TableHeadCell title='Admin' />
              <TableHeadCell title='Expiration Date' />
              <TableHeadCell title='Cluster' />
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleAccounts.map((account, idx) => {
              const isPlaceholder = account.id === PLACEHOLDER_ACCOUNT_ID

              return (
                <TableRow key={isPlaceholder ? idx : account.id}>
                  <TableCell>{idx + 1}.</TableCell>
                  <TableCell isPlaceholder={isPlaceholder}>{nameRenderer(account)}</TableCell>
                  <TableCell isPlaceholder={isPlaceholder}>{account.licenses.meetingsHot}</TableCell>
                  <TableCell isPlaceholder={isPlaceholder}>{account.licenses.meetingsSpicy}</TableCell>
                  <TableCell isPlaceholder={isPlaceholder}>{account.licenses.free}</TableCell>
                  <TableCell isPlaceholder={isPlaceholder}>{createBooleanRenderer(item => item.licenses.concierge > 0)(account)}</TableCell>
                  <TableCell isPlaceholder={isPlaceholder}>{createBooleanRenderer(item => item.licenses.conciergeLiveCalls)(account)}</TableCell>
                  <TableCell isPlaceholder={isPlaceholder}>{createBooleanRenderer(item => item.stripe.info)(account)}</TableCell>
                  <TableCell isPlaceholder={isPlaceholder}><SalesforceLinkRenderer item={account} /></TableCell>
                  <TableCell isPlaceholder={isPlaceholder}>{adminRenderer(account)}</TableCell>
                  <TableCell isPlaceholder={isPlaceholder}>{dateRenderer(account)}</TableCell>
                  <TableCell isPlaceholder={isPlaceholder}>{clusterRenderer(account)}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Provider>
  )
}
